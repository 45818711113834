import { combineReducers } from "redux";

import { productsReducer } from "./modules/products";
import { productCategoriesReducer } from "./modules/product-categories";
import { productTypesReducer } from "./modules/product-types";
import { ordersReducer } from "./modules/orders";

export default combineReducers({
  productsReducer,
  productCategoriesReducer,
  productTypesReducer,
  ordersReducer,
});
