const FETCH_PRODUCT_TYPES = "FETCH_PRODUCT_TYPES";
const GET_PRODUCT_TYPE = "GET_PRODUCT_TYPE";
const ADD_PRODUCT_TYPE = "ADD_PRODUCT_TYPE";
const UPDATE_PRODUCT_TYPE = "UPDATE_PRODUCT_TYPE";
const DELETE_PRODUCT_TYPE = "DELETE_PRODUCT_TYPE";

export {
  FETCH_PRODUCT_TYPES,
  GET_PRODUCT_TYPE,
  ADD_PRODUCT_TYPE,
  UPDATE_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE,
};
