import { FETCH_ORDERS, GET_ORDER } from "./types";

const initialState = {
  orders: [],
  order: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };

    case GET_ORDER:
      return {
        ...state,
        order: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
