import {
  FETCH_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORY,
  ADD_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
} from "./types";

export const fetchRecords = (payload) => (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_CATEGORIES, payload: payload });
};

export const addRecord = (payload) => (dispatch) => {
  dispatch({ type: ADD_PRODUCT_CATEGORY, payload: payload });
};

export const getRecord = (payload) => (dispatch) => {
  dispatch({ type: GET_PRODUCT_CATEGORY, payload: payload });
};

export const updateRecord = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT_CATEGORY, payload: payload });
};

export const deleteRecord = (payload) => (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_CATEGORY, payload: payload });
};
