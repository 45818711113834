import {
  FETCH_PRODUCT_TYPES,
  GET_PRODUCT_TYPE,
  ADD_PRODUCT_TYPE,
  UPDATE_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE,
} from "./types";

export const fetchRecords = (payload) => (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_TYPES, payload: payload });
};

export const addRecord = (payload) => (dispatch) => {
  dispatch({ type: ADD_PRODUCT_TYPE, payload: payload });
};

export const getRecord = (payload) => (dispatch) => {
  dispatch({ type: GET_PRODUCT_TYPE, payload: payload });
};

export const updateRecord = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT_TYPE, payload: payload });
};

export const deleteRecord = (payload) => (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_TYPE, payload: payload });
};
