import {
  FETCH_PRODUCTS,
  GET_PRODUCT,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  ADD_PRODUCT_CATEGORY,
  REMOVE_PRODUCT_CATEGORY,
} from "./types";

export const fetchRecords = (payload) => (dispatch) => {
  dispatch({ type: FETCH_PRODUCTS, payload: payload });
};

export const getRecord = (payload) => (dispatch) => {
  dispatch({ type: GET_PRODUCT, payload: payload });
};

export const addRecord = (payload) => (dispatch) => {
  dispatch({ type: ADD_PRODUCT, payload: payload });
};

export const updateRecord = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT, payload: payload });
};

export const deleteRecord = (payload) => (dispatch) => {
  dispatch({ type: DELETE_PRODUCT, payload: payload });
};

export const addProductCategory = (payload) => (dispatch) => {
  dispatch({ type: ADD_PRODUCT_CATEGORY, payload: payload });
};

export const removeProductCategory = (payload) => (dispatch) => {
  dispatch({ type: REMOVE_PRODUCT_CATEGORY, payload: payload });
};
