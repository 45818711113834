import {
  FETCH_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORY,
  ADD_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
} from "./types";

const initialState = {
  product_categories: [],
  product_category: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_CATEGORIES:
      return {
        ...state,
        product_categories: action.payload,
      };

    case ADD_PRODUCT_CATEGORY:
      return {
        ...state,
        product_categories: [...state.product_categories, action.payload],
      };

    case GET_PRODUCT_CATEGORY:
      return {
        ...state,
        product_category: action.payload,
      };

    case UPDATE_PRODUCT_CATEGORY:
      return {
        ...state,
      };

    case DELETE_PRODUCT_CATEGORY:
      return {
        ...state,
        product_categories: state.product_categories.filter(
          (category) => category.id !== action.payload
        ),
      };

    default:
      return {
        ...state,
      };
  }
}
