const FETCH_PRODUCTS = "FETCH_PRODUCTS";
const GET_PRODUCT = "GET_PRODUCT";
const ADD_PRODUCT = "ADD_PRODUCT";
const UPDATE_PRODUCT = "UPDATE_PRODUCT";
const DELETE_PRODUCT = "DELETE_PRODUCT";
const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
const REMOVE_PRODUCT_CATEGORY = "REMOVE_PRODUCT_CATEGORY";

export {
  FETCH_PRODUCTS,
  GET_PRODUCT,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  ADD_PRODUCT_CATEGORY,
  REMOVE_PRODUCT_CATEGORY,
};
