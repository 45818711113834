import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./redux/rootReducer";
const initialState = {};
const middleware = [thunk];

// const localhostStore = createStore(
//   rootReducer,
//   initialState,
//   compose(
//     applyMiddleware(thunk),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   )
// );

const prodStore = createStore(
  rootReducer,
  initialState,
  applyMiddleware(thunk)
);

export default prodStore;
