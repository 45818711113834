import {
  FETCH_PRODUCT_TYPES,
  GET_PRODUCT_TYPE,
  ADD_PRODUCT_TYPE,
  UPDATE_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE,
} from "./types";

const initialState = {
  product_types: [],
  product_type: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_TYPES:
      return {
        ...state,
        product_types: action.payload,
      };

    case ADD_PRODUCT_TYPE:
      return {
        ...state,
        product_types: [...state.product_types, action.payload],
      };

    case GET_PRODUCT_TYPE:
      return {
        ...state,
        product_type: action.payload,
      };

    case UPDATE_PRODUCT_TYPE:
      return {
        ...state,
      };

    case DELETE_PRODUCT_TYPE:
      return {
        ...state,
        product_types: state.product_types.filter(
          (type) => type.id !== action.payload
        ),
      };

    default:
      return {
        ...state,
      };
  }
}
