const FETCH_PRODUCT_CATEGORIES = "FETCH_PRODUCT_CATEGORIES";
const GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY";
const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";
const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY";

export {
  FETCH_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORY,
  ADD_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
};
